.group-list-container {
  a.group-container {
    text-decoration: none !important;
    color: inherit;

    border: 1px solid #888;
    border-radius: .5em;
    padding: 5px 15px;
    max-width: 600px;
    display: block;

    & + a.group-container {
      margin-top: .5em; }

    &:hover {
      background-color: rgba(0, 0, 0, .1); }

    .group-name {
      font-size: 1.35em;
      font-weight: bold; }

    .group-properties-container {
      & > div {
        text-align: center;
        &:first-child {
          text-align: left; }
        &:last-child {
          text-align: right; } } } } }
