.detailhelp {
	border-radius: .5em;
	border: 1px solid #ff8c00;
	background-color: rgba(255, 140, 0, 0.15);
	padding: 5px 10px;
	font-size: 80%;
	
	&::before {
		content: "?";
		border-radius: 0.875em;
		border: 2px solid #ff8c00;
		font-size: 125%;
		width: 1.75em;
		height: 1.75em;
		text-align: center;
		vertical-align: middle;
		display: inline-block;
		margin-left: -5px;
		margin-right: 5px;
		font-weight: bolder;
	}
}
