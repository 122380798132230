.internal-specification {
	$color-int-event: #0000FF;
	$color-int-method: #40A040;
	$color-int-property: #F0F000;
	$color-int-param: #FF8000;
	
	event {
		color: $color-int-event;
	}
	
	method {
		color: $color-int-method;
	}
	
	prop {
		color: $color-int-property;
	}
	
	h2 {
		margin-top: 1.5rem;
	}
	
	h3 {
		margin-top: 1.5rem;
	}
	
	h4 {
		margin-top: 1rem;
	}
	
	code {
		background-color: rgba(0, 0, 255, 0.045);
		color: #101010;
		line-height: 1.5;
		display: inline-block;
	}
	
	ul.specification-list {
		margin: 0px;
		margin-top: 1rem;
		padding: 0px;
		
		& > li {
			display: block;
			margin-top: 1rem;
			padding-left: 4px;
			border-left: 5px black solid;
			
			&:first-child {
				margin-top: 0rem;
			}
			
			&::first-line, prm, event, method {
				font-family: "SF Mono", "Segoe UI Mono", "Roboto Mono", Menlo, Courier, monospace;
			}
			
			prmtext {
				display: block;
				padding-left: 3rem;
				text-indent: -2rem;
			}
			
			prm {
				color: $color-int-param;
			}
			
			events::before {
				content: " ";
				display: inline-block;
				background-image: url(../img/icons/flash.png);
				background-size: 0.85rem 0.85rem;
				background-repeat: no-repeat;
				background-position: 0 0.3rem;
				width: 1rem;
				height: 1rem;
			}
			
			events {
			}
			
			&.non-code::first-line {
				font-family: inherit;
			}
			
			.property-hint {
				font-weight: normal;
				font-size: small;
			}
		}
	}
	
	ul.method-list > li {
		border-left: 5px $color-int-method solid;
	}
	
	ul.property-list > li {
		border-left: 5px $color-int-property solid;
	}
	
	ul.event-list > li {
		border-left: 5px $color-int-event solid;
	}
}
