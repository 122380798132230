.navbar.mainbar {
    position: fixed;
    z-index: 99;
    width: 100%;
    padding: 10px 50px;
    box-shadow: 0 0 5px #888;
    background: #FFF;
}

.navbar.subbar {
    padding: 10px 50px;
    width: 100%;
    margin-top: 50px;
}

.mobile-navbar {
    display: none;
    height: 3.8rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;

    .btn {
        position: absolute;
        right: 1.5rem;
        top: 1rem;

        &:first-child {
            left: .5rem;
            font-size: 20px;
        }
    }
}

.mobile-sidebar {
    display: none;
    
    .mobile-nav {
        bottom: 1.5rem;
        -webkit-overflow-scrolling: touch; 
        overflow-y: auto;
        padding: .5rem 1.5rem;
        position: fixed;
        top: 3.5rem;
        width: 12rem;
    }

    .accordion {
        margin-bottom: .75rem;
    }

    .accordion input ~ .accordion-header {
        color: #5b657a;
        font-size: .65rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    
    .accordion input:checked ~ .accordion-header {
        color: #667189;
    }

    .accordion .menu .menu-item {
        font-size: .7rem;
        padding-left: 1rem;
    }

    .accordion .menu .menu-item > a {
        background: transparent;
        color: #667189;
        display: inline-block;
    }
}

.mobile-brand {
    color: #5755d9;
    left: 1.5rem;
    position: fixed;
    top: .85rem;
    
    .mobile-logo {
        align-items: center;
        border-radius: .1rem;
        display: inline-flex;
        display: -ms-inline-flexbox;
        -ms-flex-align: center;
        height: 2rem;
        padding: .2rem;
        width: auto;
        
        &:focus, &:hover {
            text-decoration: none;
        }
    }
}
  
  
.off-canvas {
    .off-canvas-toggle {
        font-size: 1rem;
        left: 1.5rem;
        position: fixed;
        top: 1rem;
        z-index: 999;
    }
    .off-canvas-sidebar {
        width: 12rem;
    }
}
  
.mobile-brand .mobile-logo {
    img {
        display: inline-block;
        height: auto;
        width: 1.6rem;
    }

    h2 {
        display: inline-block;
        font-size: .8rem;
        font-weight: 700;
        line-height: 1.5rem;
        margin-bottom: 0;
        margin-left: .5rem;
        margin-right: .5rem;
    }
}

@media (max-width: 1280px) {
    .off-canvas .off-canvas-toggle {
        z-index: 300;
    }
    
    .mobile-sidebar {
        display: block;

        .menu .menu-item > a {
            padding: .3rem .4rem;
        }
    }

    .mobile-navbar {
        display: block;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background: rgba(248, 249, 250, .65);
        left: 0;
    }

    body {
        margin-top: 20px;
    }
    
    .navbar.mainbar {
        display: none;
    }
}

@media (max-width: 600px) {
    .mobile-navbar .btn {
        right: .9rem;
    }
    .mobile-sidebar {
        .mobile-nav {
            padding: .5rem 1rem;
        }
    }

    body {
        margin-top: 100px;
    }

    .navbar.subbar {
        display: none;
    }
}
