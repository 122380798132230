
// text-transform

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

/* start half-style hs-base */
.text-hs-base {
    position: relative;
    display: inline-block;
    overflow: hidden;
    white-space: pre;
    color: inherit;
    
    &:before {
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        width: 50%;
        content: attr(data-content);
        pointer-events: none;
        overflow: hidden;
    }
}

.text-primary, .text-primary-before:before, .text-primary-after:after {
    color: $primary-color;
}

.text-primary-dark, .text-primary-dark-before:before, .text-primary-dark-after:after {
    color: $primary-color-dark;
}

.text-light-primary, .text-primary-light-before:before, .text-primary-light-after:after {
    color: $primary-color-light;
}

.text-secondary, .text-secondary-before:before, .text-secondary-after:after {
    color: $secondary-color;
}

.text-secondary-dark, .text-secondary-dark-before:before, .text-secondary-dark-after:after {
    color: $secondary-color-dark;
}

.text-secondary-light, .text-secondary-light-before:before, .text-secondary-light-after:after {
    color: $secondary-color-light;
}

.text-dark, .text-dark-before:before, .text-dark-after:after {
    color: $dark-color;
}

.text-light, .text-light-before:before, .text-light-after:after {
    color: $light-color;
}

.text-gray, .text-gray-before:before, .text-gray-after:after {
    color: $gray-color;
}

.text-gray-dark, .text-gray-dark-before:before, .text-gray-dark-after:after {
    color: $gray-color-dark;
}

.text-gray-light, .text-gray-light-before:before, .text-gray-light-after:after {
    color: $gray-color-light;
}

.text-success, .text-success-before:before, .text-success-after:after {
    color: $success-color;
}

.text-warning, .text-warning-before:before, .text-warning-after:after {
    color: $warning-color;
}

.text-error, .text-error-before:before, .text-error-after:after {
    color: $error-color;
}

.text-highlight, .text-highlight-before:before, .text-highlight-after:after {
    color: $highlight-color;
}

.text-body-font, .text-body-font-before:before, .text-body-font-after:after {
    color: $body-font-color;
}

.text-link, .text-link-before:before, .text-link-after:after {
    color: $link-color;
}

.text-link-dark, .text-link-dark-before:before, .text-link-dark-after:after {
    color: $link-color-dark;
}

.text-small {
    font-size: 80%;
}
