$off-canvas-breakpoint: 1280px;

@import "shared";

@import "node_modules/spectre.css/src/spectre";
@import "node_modules/spectre.css/src/spectre-exp";
@import "node_modules/spectre.css/src/spectre-icons";

@import "./tables";
@import "./mainbar";
@import "./footer";
@import "./cover";
@import "./text";
@import "./buttons";
@import "./internaldocs";
@import "./timage";
@import "./conddisplay";
@import "./generalhelp";

@import "./ui/dropdown";
@import "./ui/clickable-div";
@import "./ui/tooltip";
@import "./ui/richtable";

@import "./util/spacing";
@import "./util/flex";
@import "./util/sizes";

@import "./groups";

@import "./spectre_addon/cursor";
@import "./spectre_addon/icons";
@import "./spectre_addon/buttons";

@import "./components/TravianServerList";

@import "./react-icons.sass";

html {
    scroll-behavior: smooth;
}

table {
    border-collapse: collapse;
}

.template-container {
    display: none;
}

input[type="range"].slider {
    user-select: none;
    
    &.normalwidth {
        display: initial;
        width: auto;
    }
}

.hide {
    display: none !important;
}

.hidebv {
    visibility: hidden !important;
}

.debugcss * {
    border: 1px solid red !important;
}

.loadingScreen {
    width: 60rem;
    height: 1.5rem;
    font-size: 1rem;
    background-color: #D0D0D0;
    
    & > .loadingScreenBar {
        position: absolute;
        background-color: $primary-color;
        width: 0;
        height: 1.5rem;
        transition: width .3s;
    }
    
    & > .loadingScreenText {
        position: absolute;
        height: 1.5rem;
        width: 60rem;
        text-align: center;
        color: white;
    }
}

.flowingtext-image {
    float: right;
}

[clear-image-float] {
    clear: right;
}

.tutorial-default {
    column-width: 18rem;
    text-align: justify;
}

.floattext-default {
    column-width: 36rem;
    text-align: justify;
}

.floattext-1-column {
    max-width: 36rem;
    text-align: justify;
}

table.datatable-no-forcedwidth .datatable-forcedwidth {
    overflow-x: visible !important;
}

.scrollable {
    overflow-x: auto;
}

.globalhelplink {
    display: inline-block;
    border: 1px solid $primary-color;
    border-radius: .65em;
    width: 1.3em;
    height: 1.3em;
    text-align: center;
    
    &:hover, &:focus {
        text-decoration: none;
    }
}

.lang-dbg {
    &[data-isfallback="true"] {
        background-color: yellow;
        color: red;
    }
}

.copy-paste-parser {
    width: 12em;
    height: 5em;
    border: 1px solid black;
    box-shadow: 0 0 1px 1px #888 inset;
    padding: 0.5em;
    
    &::after {
        content: attr(data-hintcontent);
        font-size: small;
        color: #888;
    }
}

// mainmenu
.mainmenu-version-modifier-customspeed {
    display: none;
}

.mainmenu-version-modifier-customspeed-enable .mainmenu-version-modifier-customspeed {
    display: block;
}

.mainmenu-version-modifier-disable-tlfeatures [data-versionfeature^="tl"] {
    display: none;
}
.mainmenu-version-modifier-disable-tkfeatures [data-versionfeature^="tk"] {
    display: none;
}

span, td, div {
    &[data-editable="1"] {
        background-color: rgba(0, 0, 0, 0.06);
        cursor: pointer;
        border-radius: 0.4em;
        transition-property: background-color;
        transition-duration: 0.4s;
        padding: 0 0.4em;
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.only-registered-users {
    font-size: 80%;
    font-style: italic;
}

.basinotice {
    margin-top: 35px;
    font-size: 80%;
}

.gameworld-error-entry {
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
    margin-top: -1px;
    margin-bottom: 0;
    padding: .8rem 0;
    font-size: 80%;
}

.modal.modal-lg {
    .modal-container {
        box-shadow: 0 0.2rem 0.5rem rgba(48,55,66,.3);
    }
    .modal-overlay {
        background-color: rgba(247,248,249,.75) !important;
    }
}

.alternate, table.alternate-table > tbody {
    > *:nth-child(odd) {
        background-color: #F4F4F4;
        
        &:hover {
            background-color: #E0E0E0;
        }
    }
    
    > *:nth-child(even) {
        background-color: #FFFFFF;
        
        &:hover {
            background-color: #E0E0E0;
        }
    }
}

.dashboard-header {
    background-color: $dark-color;
    padding: 0.5em;
    margin-bottom: 1em;
    margin-top: 1em;
}

.autocomplete_list {
    background-color: white;
    border: 1px solid #888;
    box-shadow: 2px 2px 0 1px #EFEFEF;
    
    [data-path='root/body/row']:hover {
        background-color: #E8E8E8;
        cursor: pointer;
    }
}

.defaultTableHeader {
    font-weight: bold;
}
.sortableHeader {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.editable-text-field {
    margin: 0;
}
div.editable-text-field {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #E3E3E3;
    cursor: pointer;
    border-radius: 3pt;
    &:hover {
        background-color: #D5D5D5;
    }
}
input.editable-text-field {
    width: 100%;
    font-size: .8em;
    height: calc(100% - 2pt);
}

.container.no-margin {
    margin: 0;
}
.container.no-padding {
    padding: 0;
}

form[novalidate] input:invalid {
    background-color: #fff !important;
    border-color: $border-color-dark !important;
    &:focus {
        border-color: $primary-color !important;
        box-shadow: 0 0 0 .1rem rgba($primary-color, .2) !important;
    }
}

.d-grid {
    display: grid;
}
.align-items-center {
    align-items: center;
}
.justify-content-center {
    justify-content: center;
}
