/* Konvention:
 * Einheitenicons sind immer 20x20
 * Gebäudebilder immer 50x50
 * Itembilder immer 50x50 [obwohl O-Größe 60x60]
 * Ressourcenicons immer 16x16
 * travicon Icons sind immer quadratisch und haben ihre Größe am Anfang stehen
 * Abweichende Größen haben die Klasse iconxxx für xxx Pixel, z.B. icon32 für ein abweichendes 32x32 Icon (siehe Ressourcenicon)
 */

// All icons are TK icons, if available

.timage {
	display: inline-block;
	padding: 0px;
	margin: 0px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: 0 0;
	
	&.intext {
		margin-bottom: -2px;
	}
	
	&.disabled {
		filter: grayscale(100%);
	}
	
	&.buildingimage {
		width: 50px;
		height: 50px;
		
		&.timage-text > * {
			font-size: 32px;
			text-align: center;
			width: 100%;
			height: 100%;
			margin: 0px;
			padding: 0px;
			background-color: rgba(255, 255, 255, 0.75);
		}
		
		// auto generated: dbg/timage_building
		&[data-building="wood"], &[data-building="b1"] { background-image: url(../img/travian/building/tk/1.png); }
		&[data-building="clay"], &[data-building="b2"] { background-image: url(../img/travian/building/tk/2.png); }
		&[data-building="iron"], &[data-building="b3"] { background-image: url(../img/travian/building/tk/3.png); }
		&[data-building="crop"], &[data-building="b4"] { background-image: url(../img/travian/building/tk/4.png); }
		&[data-building="sawmill"], &[data-building="b5"] { background-image: url(../img/travian/building/tk/5.png); }
		&[data-building="brickyard"], &[data-building="b6"] { background-image: url(../img/travian/building/tk/6.png); }
		&[data-building="iron-foundry"], &[data-building="ironfoundry"], &[data-building="b7"] { background-image: url(../img/travian/building/tk/7.png); }
		&[data-building="grain-mill"], &[data-building="grainmill"], &[data-building="mill"], &[data-building="b8"] { background-image: url(../img/travian/building/tk/8.png); }
		&[data-building="backery"], &[data-building="b9"] { background-image: url(../img/travian/building/tk/9.png); }
		&[data-building="warehouse"], &[data-building="b10"] { background-image: url(../img/travian/building/tk/10.png); }
		&[data-building="granary"], &[data-building="b11"] { background-image: url(../img/travian/building/tk/11.png); }
		&[data-building="smithy"], &[data-building="b13"] { background-image: url(../img/travian/building/tk/13.png); }
		&[data-building="tournament-square"], &[data-building="tournamentsquare"], &[data-building="ts"], &[data-building="tp"], &[data-building="b14"] { background-image: url(../img/travian/building/tk/14.png); }
		&[data-building="main-building"], &[data-building="mainbuilding"], &[data-building="mb"], &[data-building="b15"] { background-image: url(../img/travian/building/tk/15.png); }
		&[data-building="rally-point"], &[data-building="rallypoint"], &[data-building="rp"], &[data-building="b16"] { background-image: url(../img/travian/building/tk/16.png); }
		&[data-building="marketplace"], &[data-building="mp"], &[data-building="b17"] { background-image: url(../img/travian/building/tk/17.png); }
		&[data-building="embassy"], .showif-set-tv-kingdoms &[data-building="oasisbuilding"], &[data-building="b18"] { background-image: url(../img/travian/building/tk/18.png); }
		&[data-building="herosmansion"], .showif-set-tv-legends &[data-building="oasisbuilding"], &[data-building="b37"] { background-image: url(../img/travian/building/tl/g37-ltr.png); }
		&[data-building="barracks"], &[data-building="b19"] { background-image: url(../img/travian/building/tk/19.png); }
		&[data-building="stable"], &[data-building="b20"] { background-image: url(../img/travian/building/tk/20.png); }
		&[data-building="workshop"], &[data-building="b21"] { background-image: url(../img/travian/building/tk/21.png); }
		&[data-building="academy"], &[data-building="b22"] { background-image: url(../img/travian/building/tk/22.png); }
		&[data-building="cranny"], &[data-building="b23"] { background-image: url(../img/travian/building/tk/23.png); }
		&[data-building="town-hall"], &[data-building="b24"] { background-image: url(../img/travian/building/tk/24.png); }
		&[data-building="residence"], &[data-building="b25"] { background-image: url(../img/travian/building/tk/25.png); }
		&[data-building="palace"], &[data-building="b26"] { background-image: url(../img/travian/building/tk/26.png); }
		&[data-building="treasury-active"], &[data-building="b27"] { background-image: url(../img/travian/building/tk/27.png); }
		&[data-building="trade-office"], &[data-building="b28"] { background-image: url(../img/travian/building/tk/28.png); }
		&[data-building="great-barracks"], &[data-building="greatbarracks"], &[data-building="gb"], &[data-building="b29"] { background-image: url(../img/travian/building/tk/29.png); }
		&[data-building="great-stable"], &[data-building="greatstable"], &[data-building="gs"], &[data-building="b30"] { background-image: url(../img/travian/building/tk/30.png); }
		&[data-building="city-wall"], &[data-building="citywall"], &[data-building="wall-roman"], &[data-building="b31"] { background-image: url(../img/travian/building/tk/31.png); }
		&[data-building="earth-wall"], &[data-building="earthwall"], &[data-building="wall-teuton"], &[data-building="b32"] { background-image: url(../img/travian/building/tk/32.png); }
		&[data-building="palisade"], &[data-building="wall-gaul"], &[data-building="b33"] { background-image: url(../img/travian/building/tk/33.png); }
		&[data-building="stonemason"], &[data-building="b34"] { background-image: url(../img/travian/building/tk/34.png); }
		&[data-building="brewery"], &[data-building="b35"] { background-image: url(../img/travian/building/tk/35.png); }
		&[data-building="trapper"], &[data-building="b36"] { background-image: url(../img/travian/building/tk/36.png); }
		&[data-building="great-warehouse"], &[data-building="greatwarehouse"], &[data-building="gw"], &[data-building="b38"] { background-image: url(../img/travian/building/tk/38.png); }
		&[data-building="great-granary"], &[data-building="greatgranary"], &[data-building="gg"], &[data-building="b39"] { background-image: url(../img/travian/building/tk/39.png); }
		&[data-building="wonder-of-the-world"], &[data-building="ww"], &[data-building="b40"] { background-image: url(../img/travian/building/tk/40.png); }
		&[data-building="horse-drinking-trough"], &[data-building="hdt"], &[data-building="b41"] { background-image: url(../img/travian/building/tk/41.png); }
		&[data-building="water-ditch"], &[data-building="waterditch"], &[data-building="b42"] { background-image: url(../img/travian/building/tk/42.png); }
		&[data-building="natarian-wall"], &[data-building="natarianwall"], &[data-building="wall-natar"], &[data-building="b43"] { background-image: url(../img/travian/building/tk/43.png); }
		&[data-building="teahouse"], &[data-building="b44"] { background-image: url(../img/travian/building/tk/44.png); }
		&[data-building="treasury-hidden"], &[data-building="b45"] { background-image: url(../img/travian/building/tk/45.png); }
		// end auto generated
	}
	
	&.itemimage {
		width: 50px;
		height: 50px;
		background-size: 600px 550px;
		&[data-building="helmet-barracks-tier-3"] {
			background-image: url(../img/travian/item/generic.png);
			background-position: -300px -200px;
		}
		&[data-building="helmet-stable-tier-3"] {
			background-image: url(../img/travian/item/generic.png);
			background-position: -300px -50px;
		}
	}
	
	&.ressimage {
		width: 16px;
		height: 16px;
		background-image: url(../img/travian/icon/tk/general.png);
		background-size: auto;
		
		&[data-ress="r1"] { background-position: -276px -382px; }
		&[data-ress="r2"] { background-position: -441px -32px; }
		&[data-ress="r3"] { background-position: -425px -192px; }
		&[data-ress="r4"] { background-position: -191px -416px; }
		&[data-ress="r5"] { background-position: -441px -16px; }
		
		&.icon22 {
			width: 22px;
			height: 22px;
			
			&[data-ress="r1"] { background-position: -44px -360px; }
			&[data-ress="r2"] { background-position: -387px -110px; }
			&[data-ress="r3"] { background-position: -264px -360px; }
			&[data-ress="r4"] { background-position: -387px -132px; }
		}
		
		&.icon40 {
			width: 40px;
			height: 40px;
			
			&[data-ress="r1"] { background-position: -282px -0px; }
			&[data-ress="r2"] { background-position: -147px -221px; }
			&[data-ress="r3"] { background-position: -187px -221px; }
			&[data-ress="r4"] { background-position: -227px -221px; }
		}
	}
	
	&.arteicon {
		width: 28px;
		height: 28px;
		background-size: auto;
		background-image: url(../img/travian/icon/tl/artefacts.png);
		
		&[data-arte="buildingplans"] { background-position: 0px 0px; }
		&[data-arte="architect"] { background-position: 0px -28px; }
		&[data-arte="boots"] { background-position: 0px -56px; }
		&[data-arte="eagles"] { background-position: 0px -84px; }
		&[data-arte="diet"] { background-position: 0px -112px; }
		&[data-arte="trainers"] { background-position: 0px -140px; }
		&[data-arte="storage"] { background-position: 0px -168px; }
		&[data-arte="confusion"] { background-position: 0px -196px; }
		&[data-arte="fools"] { background-position: 0px -224px; }
		&[data-arte="victory"] { background-position: 0px -252px; }
	}
	
	&.allybonus {
		width: 16px;
		height: 16px;
		background-size: auto;
		background-image: url(../img/travian/icon/tl/allybonus.png);
		
		&[data-bonus="recruitment"] { background-position: 0px 0px; }
		&[data-bonus="philosophy"] { background-position: -16px 0px; }
		&[data-bonus="metallurgy"] { background-position: -32px 0px; }
		&[data-bonus="commerce"] { background-position: -48px 0px; }
		&[data-bonus="progress"] { background-position: -64px 0px; }
	}
	
	&.travicon {
		width: 16px;
		height: 16px;
		background-size: auto;
		
		&[data-icon="14-error"] {
			width: 14px;
			height: 14px;
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -441px -333px;
		}
		
		&[data-icon="16-combatstat-a"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -50px -225px;
		}
		&[data-icon="16-combatstat-di"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -48px -416px;
		}
		&[data-icon="16-combatstat-dk"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -96px -416px;
		}
		
		&[data-icon="16-report-att-red"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -219px -180px;
		}
		&[data-icon="16-report-att-black"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -50px -225px;
		}
		
		&[data-icon="16-report-def-black"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -235px -180px;
		}
		
		&[data-icon="16-report-spy-black"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -267px -180px;
		}
		
		&[data-icon="16-plus-black"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -425px -272px;
		}
		
		&[data-icon="16-capital"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -0px -400px;
		}
		&[data-icon="16-village"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -441px -272px;
		}
		&[data-icon="16-city"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -441px -272px;
			background-position: -64px -261.81812px;
			background-size: 332.36364px 314.18182px;
		}
		&[data-icon="16-population"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -425px -80px;
		}
		&[data-icon="16-king"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -425px -96px;
		}
		&[data-icon="16-duke"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -272px -400px;
		}
		&[data-icon="16-governor"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -48px -400px;
		}
		&[data-icon="16-influence"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -425px -240px;
		}
		&[data-icon="16-bbash"] {
			background-image: url(../img/travian/icon/tk/general2.png);
			background-position: 0 -353px;
		}
		&[data-icon="16-nighttruce"] {
			background-image: url(../img/travian/icon/tk/general2.png);
			background-position: 0 -625px;
		}
		&[data-icon="16-cropdiet"] {
			background-image: url(../img/travian/icon/tk/general2.png);
			background-position: 0 -385px;
		}
		&[data-icon="16-coordinates"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -352px -416px;
		}
		&[data-icon="16-player"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -320px -416px;
		}
		&[data-icon="16-players"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -425px -160px;
		}
		&[data-icon="16-settler"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -304px -400px;
		}
		&[data-icon="16-treasures"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -409px -32px;
		}
		&[data-icon="16-treasures-bw"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -409px -48px;
		}
		&[data-icon="16-vicpoints"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -340px -382px;
		}
		&[data-icon="16-robbers"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -65.33333px -217.33333px;
			background-size: 304.66667px 288px;
		}
		&[data-icon="16-culture"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -112px -416px;
		}
		&[data-icon="16-cropoasis"] {
			background-image: url(../img/travian/icon/tk/map/oasis41.png);
			background-position: 2px 2px;
			background-size: 16px 16px;
		}
		&[data-icon="16-c9"], &[data-icon="16-c15"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -191px -416px;
			&::after {
				content: "9";
				margin-left: 11px;
				display: inline-block;
				font-size: 0.6em;
			}
		}
		&[data-icon="16-c15"]::after { content: "15"; }
		
		&[data-icon="16-infantry"] {
			background-image: url(../img/travian/icon/tk/buildingicons.png);
			background-position: -32px 0px;
		}
		&[data-icon="16-cavalry"] {
			background-image: url(../img/travian/icon/tk/buildingicons.png);
			background-position: -16px -32px;
		}
		&[data-icon="16-artillery"] {
			background-image: url(../img/travian/icon/tk/buildingicons.png);
			background-position: 0px 0px;
		}
		
		.showif-set-tv-legends &[data-icon="16-game-icon"], &[data-icon="16-game-icon-tl"], &[data-icon="16-game-icon-t4"] {
			background-image: url(../img/travian/icon/tl/favicon.png);
		}
		.showif-set-tv-kingdoms &[data-icon="16-game-icon"], &[data-icon="16-game-icon-tk"], &[data-icon="16-game-icon-t5"] {
			background-image: url(../img/travian/icon/tk/favicon.png);
		}
		
		&[data-icon="25-report-att-green"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -125px -200px;
		}
		&[data-icon="25-report-att-yellow"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -200px -130px;
		}
		&[data-icon="25-report-att-red"] {
			background-image: url(../img/travian/icon/tk/movement.png);
			background-position: -50px -200px;
		}
		
		&[data-icon="48x16-resources"] {
			background-image: url(../img/travian/icon/tk/general.png);
			background-position: -229px -296px;
			width: 48px;
			height: 16px;
		}
		
		&[data-icon^="25"] {
			width: 25px;
			height: 25px;
		}
	}
	
	&.oasisIcon {
		width: 20px;
		height: 20px;
		background-size: auto;
		
		&.oasisIcon {
			&[data-oasis="11"] {
				background-image: url(../img/travian/icon/tk/map/oasis11.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="21"] {
				background-image: url(../img/travian/icon/tk/map/oasis21.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="31"] {
				background-image: url(../img/travian/icon/tk/map/oasis31.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="41"] {
				background-image: url(../img/travian/icon/tk/map/oasis41.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="10"] {
				background-image: url(../img/travian/icon/tk/map/oasis10.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="20"] {
				background-image: url(../img/travian/icon/tk/map/oasis20.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="30"] {
				background-image: url(../img/travian/icon/tk/map/oasis30.png);
				background-position: -4px -4px;
				
			}
			&[data-oasis="40"] {
				background-image: url(../img/travian/icon/tk/map/oasis40.png);
				background-position: -4px -4px;
				
			}
		}
	}

	&.countryicon {
		background-image: url(../img/travian/icon/tk/flags.png);
		background-position: 0 -5240px;
		background-size: auto;
		width: 30px;
		height: 20px;
		
		&[data-country="cz"] {
		    background-position: 0 -1200px;
		}

		&[data-country="de"] {
		    background-position: 0 -1720px;
		}

		&[data-country="ru"] {
		    background-position: 0 -3840px;
		}

		&[data-country="fr"] {
		    background-position: 0 -1600px;
		}

		&[data-country="dz"] {
		    background-position: 0 -80px;
		}

		&[data-country="ad"] {
		    background-position: 0 -120px;
		}

		&[data-country="ar"] {
		    background-position: 0 -220px;
		}

		&[data-country="au"] {
		    background-position: 0 -280px;
		}

		&[data-country="at"] {
		    background-position: 0 -300px;
		}

		&[data-country="bh"] {
		    background-position: 0 -360px;
		}

		&[data-country="be"] {
		    background-position: 0 -460px;
		}

		&[data-country="ba"] {
		    background-position: 0 -580px;
		}

		&[data-country="br"] {
		    background-position: 0 -620px;
		}

		&[data-country="bg"] {
		    background-position: 0 -700px;
		}

		&[data-country="ca"] {
		    background-position: 0 -800px;
		}

		&[data-country="cl"] {
		    background-position: 0 -920px;
		}

		&[data-country="cn"] {
		    background-position: 0 -940px;
		}

		&[data-country="co"] {
		    background-position: 0 -1000px;
		}

		&[data-country="hr"] {
		    background-position: 0 -1120px;
		}

		&[data-country="cw"] {
		    background-position: 0 -1160px;
		}

		&[data-country="dk"] {
		    background-position: 0 -1240px;
		}

		&[data-country="ec"] {
		    background-position: 0 -1340px;
		}

		&[data-country="eg"] {
		    background-position: 0 -1360px;
		}

		&[data-country="ee"] {
		    background-position: 0 -1460px;
		}

		&[data-country="fi"] {
		    background-position: 0 -1580px;
		}

		&[data-country="gr"] {
		    background-position: 0 -1800px;
		}

		&[data-country="hk"] {
		    background-position: 0 -2020px;
		}

		&[data-country="hu"] {
		    background-position: 0 -2040px;
		}

		&[data-country="id"] {
		    background-position: 0 -2100px;
		}

		&[data-country="iq"] {
		    background-position: 0 -2140px;
		}

		&[data-country="il"] {
		    background-position: 0 -2200px;
		}

		&[data-country="it"] {
		    background-position: 0 -2220px;
		}

		&[data-country="jp"] {
		    background-position: 0 -2260px;
		}

		&[data-country="jo"] {
		    background-position: 0 -2300px;
		}

		&[data-country="kw"] {
		    background-position: 0 -2400px;
		}

		&[data-country="lv"] {
		    background-position: 0 -2460px;
		}

		&[data-country="lb"] {
		    background-position: 0 -2500px;
		}

		&[data-country="lt"] {
		    background-position: 0 -2600px;
		}

		&[data-country="lu"] {
		    background-position: 0 -2620px;
		}

		&[data-country="my"] {
		    background-position: 0 -2720px;
		}

		&[data-country="mx"] {
		    background-position: 0 -2920px;
		}

		&[data-country="ma"] {
		    background-position: 0 -3060px;
		}

		&[data-country="nl"] {
		    background-position: 0 -3220px;
		}

		&[data-country="nz"] {
		    background-position: 0 -3280px;
		}

		&[data-country="no"] {
		    background-position: 0 -3460px;
		}

		&[data-country="pe"] {
		    background-position: 0 -3640px;
		}

		&[data-country="ph"] {
		    background-position: 0 -3660px;
		}

		&[data-country="pl"] {
		    background-position: 0 -3700px;
		}

		&[data-country="pt"] {
		    background-position: 0 -3720px;
		}

		&[data-country="qa"] {
		    background-position: 0 -3760px;
		}

		&[data-country="ro"] {
		    background-position: 0 -3820px;
		}

		&[data-country="sa"] {
		    background-position: 0 -4060px;
		}

		&[data-country="rs"] {
		    background-position: 0 -4120px;
		}

		&[data-country="sg"] {
		    background-position: 0 -4180px;
		}

		&[data-country="sk"] {
		    background-position: 0 -4200px;
		}

		&[data-country="sl"] {
		    background-position: 0 -4220px;
		}

		&[data-country="za"] {
		    background-position: 0 -4300px;
		}

		&[data-country="kr"] {
		    background-position: 0 -4340px;
		}

		&[data-country="es"] {
		    background-position: 0 -4400px;
		}

		&[data-country="se"] {
		    background-position: 0 -4500px;
		}

		&[data-country="ch"] {
		    background-position: 0 -4520px;
		}

		&[data-country="sy"] {
		    background-position: 0 -4540px;
		}

		&[data-country="tw"] {
		    background-position: 0 -4560px;
		}

		&[data-country="th"] {
		    background-position: 0 -4620px;
		}

		&[data-country="tr"] {
		    background-position: 0 -4740px;
		}

		&[data-country="dev"],&[data-country="ua"] {
		    background-position: 0 -4840px;
		}

		&[data-country="ae"] {
		    background-position: 0 -5220px;
		}

		&[data-country="gb"] {
		    background-position: 0 -4880px;
		}

		&[data-country="us"] {
		    background-position: 0 -4920px;
		}

		&[data-country="uy"] {
		    background-position: 0 -4960px;
		}

		&[data-country="ve"] {
		    background-position: 0 -5060px;
		}

		&[data-country="ye"] {
		    background-position: 0 -5160px;
		}
	}

	// semi-auto generated
	.showif-set-tv-kingdoms &.unitimage {
		width: 20px;
		height: 20px;
		background-image: url(../img/travian/icon/tk/unitsmall.png);
		background-size: auto;
		
		// semiauto generated (copy & paste + search & replace)
		&[data-tribe="1"][data-unit="t1"] {
		    background-position: -60px -100px;
		}
		
		&[data-tribe="1"][data-unit="t2"] {
		    background-position: -80px -100px;
		}
		
		&[data-tribe="1"][data-unit="t3"] {
		    background-position: -100px -100px;
		}
		
		&[data-tribe="1"][data-unit="t4"] {
		    background-position: -120px 0;
		}
		
		&[data-tribe="1"][data-unit="t5"] {
		    background-position: -120px -20px;
		}
		
		&[data-tribe="1"][data-unit="t6"] {
		    background-position: -120px -40px;
		}
		
		&[data-tribe="1"][data-unit="t7"] {
		    background-position: -120px -60px;
		}
		
		&[data-tribe="1"][data-unit="t8"] {
		    background-position: -120px -80px;
		}
		
		&[data-tribe="1"][data-unit="t9"] {
		    background-position: -120px -100px;
		}
		
		&[data-tribe="1"][data-unit="t10"] {
		    background-position: -40px -100px;
		}
		
		&[data-tribe="2"][data-unit="t1"] {
		    background-position: -20px -120px;
		}
		
		&[data-tribe="2"][data-unit="t2"] {
		    background-position: -40px -120px;
		}
		
		&[data-tribe="2"][data-unit="t3"] {
		    background-position: -60px -120px;
		}
		
		&[data-tribe="2"][data-unit="t4"] {
		    background-position: -80px -120px;
		}
		
		&[data-tribe="2"][data-unit="t5"] {
		    background-position: -100px -120px;
		}
		
		&[data-tribe="2"][data-unit="t6"] {
		    background-position: -120px -120px;
		}
		
		&[data-tribe="2"][data-unit="t7"] {
		    background-position: -140px 0;
		}
		
		&[data-tribe="2"][data-unit="t8"] {
		    background-position: -140px -20px;
		}
		
		&[data-tribe="2"][data-unit="t9"] {
		    background-position: -140px -40px;
		}
		
		&[data-tribe="2"][data-unit="t10"] {
		    background-position: 0 -120px;
		}
		
		&[data-tribe="3"][data-unit="t1"] {
		    background-position: -100px -20px;
		}
		
		&[data-tribe="3"][data-unit="t2"] {
		    background-position: 0 -20px;
		}
		
		&[data-tribe="3"][data-unit="t3"] {
		    background-position: -20px -20px;
		}
		
		&[data-tribe="3"][data-unit="t4"] {
		    background-position: -40px 0;
		}
		
		&[data-tribe="3"][data-unit="t5"] {
		    background-position: -40px -20px;
		}
		
		&[data-tribe="3"][data-unit="t6"] {
		    background-position: 0 -40px;
		}
		
		&[data-tribe="3"][data-unit="t7"] {
		    background-position: -20px -40px;
		}
		
		&[data-tribe="3"][data-unit="t8"] {
		    background-position: -40px -40px;
		}
		
		&[data-tribe="3"][data-unit="t9"] {
		    background-position: -60px 0;
		}
		
		&[data-tribe="3"][data-unit="t10"] {
		    background-position: -20px 0;
		}
		
		&[data-tribe="4"][data-unit="t1"] {
		    background-position: -60px -80px;
		}
		
		&[data-tribe="4"][data-unit="t2"] {
		    background-position: -80px -80px;
		}
		
		&[data-tribe="4"][data-unit="t3"] {
		    background-position: -100px 0;
		}
		
		&[data-tribe="4"][data-unit="t4"] {
		    background-position: 0 0;
		}
		
		&[data-tribe="4"][data-unit="t5"] {
		    background-position: -100px -40px;
		}
		
		&[data-tribe="4"][data-unit="t6"] {
		    background-position: -100px -60px;
		}
		
		&[data-tribe="4"][data-unit="t7"] {
		    background-position: -100px -80px;
		}
		
		&[data-tribe="4"][data-unit="t8"] {
		    background-position: 0 -100px;
		}
		
		&[data-tribe="4"][data-unit="t9"] {
		    background-position: -20px -100px;
		}
		
		&[data-tribe="4"][data-unit="t10"] {
		    background-position: -40px -80px;
		}
		
		&[data-tribe="5"][data-unit="t1"] {
		    background-position: -20px -60px;
		}
		
		&[data-tribe="5"][data-unit="t2"] {
		    background-position: -40px -60px;
		}
		
		&[data-tribe="5"][data-unit="t3"] {
		    background-position: -60px -60px;
		}
		
		&[data-tribe="5"][data-unit="t4"] {
		    background-position: -80px 0;
		}
		
		&[data-tribe="5"][data-unit="t5"] {
		    background-position: -80px -20px;
		}
		
		&[data-tribe="5"][data-unit="t6"] {
		    background-position: -80px -40px;
		}
		
		&[data-tribe="5"][data-unit="t7"] {
		    background-position: -80px -60px;
		}
		
		&[data-tribe="5"][data-unit="t8"] {
		    background-position: 0 -80px;
		}
		
		&[data-tribe="5"][data-unit="t9"] {
		    background-position: -20px -80px;
		}
		
		&[data-tribe="5"][data-unit="t10"] {
		    background-position: 0 -60px;
		}
		// end semiauto generated
	}
	
	.showif-set-tv-legends &.unitimage {
		width: 20px;
		height: 20px;
		background-size: auto;
		
		&[data-tribe="1"] {
			background-image: url(../img/travian/icon/tl/units-t1.png);
		}
		&[data-tribe="2"] {
			background-image: url(../img/travian/icon/tl/units-t2.png);
		}
		&[data-tribe="3"] {
			background-image: url(../img/travian/icon/tl/units-t3.png);
		}
		&[data-tribe="4"] {
			background-image: url(../img/travian/icon/tl/units-t4.png);
		}
		&[data-tribe="5"] {
			background-image: url(../img/travian/icon/tl/units-t5.png);
		}
		&[data-tribe="6"] {
			background-image: url(../img/travian/icon/tl/units-t6.png);
		}
		&[data-tribe="7"] {
			background-image: url(../img/travian/icon/tl/units-t7.png);
		}
		&[data-tribe="8"] {
			background-image: url(../img/travian/icon/tl/units-t8.png);
		}
		&[data-unit="t1"] {
			background-position: 2px 2px;
		}
		&[data-unit="t2"] {
			background-position: -18px 2px;
		}
		&[data-unit="t3"] {
			background-position: -38px 2px;
		}
		&[data-unit="t4"] {
			background-position: -58px 2px;
		}
		&[data-unit="t5"] {
			background-position: -78px 2px;
		}
		&[data-unit="t6"] {
			background-position: -98px 2px;
		}
		&[data-unit="t7"] {
			background-position: -118px 2px;
		}
		&[data-unit="t8"] {
			background-position: -138px 2px;
		}
		&[data-unit="t9"] {
			background-position: -158px 2px;
		}
		&[data-unit="t10"] {
			background-position: -178px 2px;
		}
	}
	
	// auto generated achievement icons
	&.tkachiev48 {
		display: inline-block;
		background-repeat: no-repeat;
		width: 29px;
		height: 29px;
		background-image: url(../img/travian/icon/tk/achievements.png);
		background-size: auto;
		margin-top: 10px;
		margin-left: 10px;
	}
	&.tkachiev80 {
		display: inline-block;
		background-repeat: no-repeat;
		width: 50px;
		height: 50px;
		background-image: url(../img/travian/icon/tk/achievements.png);
		background-size: auto;
		margin-top: 15px;
		margin-left: 15px;
	}
	&.tkachievframe48 {
		width: 48px;
		height: 48px;
		background-image: url(../img/travian/icon/tk/achievements.png);
		background-size: auto;
	}
	&.tkachievframe80 {
		width: 80px;
		height: 80px;
		background-image: url(../img/travian/icon/tk/achievements.png);
		background-size: auto;
	}
	
	&.tkachievframe48[data-level="1"] {
		background-position: 0 -2339px;
	}
	&.tkachievframe48[data-level="2"] {
		background-position: 0 -2579px;
	}
	&.tkachievframe48[data-level="3"] {
		background-position: 0 -2531px;
	}
	&.tkachievframe48[data-level="4"] {
		background-position: 0 -2483px;
	}
	&.tkachievframe48[data-level="5"] {
		background-position: 0 -2435px;
	}
	&.tkachievframe48[data-level="special"] {
		background-position: 0 -2387px;
	}
	&.tkachievframe48[data-level="mystery"] {
		background-position: 0 -2291px;
	}
	
	&.tkachievframe80[data-level="1"] {
		background-position: 0 -6977px;
	}
	&.tkachievframe80[data-level="2"] {
		background-position: 0 -6897px;
	}
	&.tkachievframe80[data-level="3"] {
		background-position: 0 -6817px;
	}
	&.tkachievframe80[data-level="4"] {
		background-position: 0 -6737px;
	}
	&.tkachievframe80[data-level="5"] {
		background-position: 0 -6657px;
	}
	&.tkachievframe80[data-level="special"] {
		background-position: 0 -6577px;
	}
	&.tkachievframe80[data-level="mystery"] {
		background-position: 0 -7057px;
	}
	
	&.tkachiev48[data-achievement="9"] {
		background-position: 0 -29px;
	}
	&.tkachiev48[data-achievement="8"] {
		background-position: 0 -58px;
	}
	&.tkachiev48[data-achievement="7"] {
		background-position: 0 -87px;
	}
	&.tkachiev48[data-achievement="69"] {
		background-position: 0 -116px;
	}
	&.tkachiev48[data-achievement="72"] {
		background-position: 0 -145px;
	}
	&.tkachiev48[data-achievement="68"] {
		background-position: 0 -174px;
	}
	&.tkachiev48[data-achievement="67"] {
		background-position: 0 -203px;
	}
	&.tkachiev48[data-achievement="71"] {
		background-position: 0 -232px;
	}
	&.tkachiev48[data-achievement="66"] {
		background-position: 0 -261px;
	}
	&.tkachiev48[data-achievement="1"] {
		background-position: 0 -290px;
	}
	&.tkachiev48[data-achievement="10"] {
		background-position: 0 -319px;
	}
	&.tkachiev48[data-achievement="103"] {
		background-position: 0 -348px;
	}
	&.tkachiev48[data-achievement="104"] {
		background-position: 0 -377px;
	}
	&.tkachiev48[data-achievement="105"] {
		background-position: 0 -406px;
	}
	&.tkachiev48[data-achievement="106"] {
		background-position: 0 -435px;
	}
	&.tkachiev48[data-achievement="107"] {
		background-position: 0 -464px;
	}
	&.tkachiev48[data-achievement="108"] {
		background-position: 0 -493px;
	}
	&.tkachiev48[data-achievement="109"] {
		background-position: 0 -522px;
	}
	&.tkachiev48[data-achievement="11"] {
		background-position: 0 -551px;
	}
	&.tkachiev48[data-achievement="12"] {
		background-position: 0 -580px;
	}
	&.tkachiev48[data-achievement="13"] {
		background-position: 0 -609px;
	}
	&.tkachiev48[data-achievement="14"] {
		background-position: 0 -638px;
	}
	&.tkachiev48[data-achievement="15"] {
		background-position: 0 -667px;
	}
	&.tkachiev48[data-achievement="16"] {
		background-position: 0 -696px;
	}
	&.tkachiev48[data-achievement="17"] {
		background-position: 0 -725px;
	}
	&.tkachiev48[data-achievement="18"] {
		background-position: 0 -754px;
	}
	&.tkachiev48[data-achievement="19"] {
		background-position: 0 -783px;
	}
	&.tkachiev48[data-achievement="2"] {
		background-position: 0 -812px;
	}
	&.tkachiev48[data-achievement="20"] {
		background-position: 0 -841px;
	}
	&.tkachiev48[data-achievement="21"] {
		background-position: 0 -870px;
	}
	&.tkachiev48[data-achievement="22"] {
		background-position: 0 -899px;
	}
	&.tkachiev48[data-achievement="23"] {
		background-position: 0 -928px;
	}
	&.tkachiev48[data-achievement="24"] {
		background-position: 0 -957px;
	}
	&.tkachiev48[data-achievement="25"] {
		background-position: 0 -986px;
	}
	&.tkachiev48[data-achievement="26"] {
		background-position: 0 -1015px;
	}
	&.tkachiev48[data-achievement="27"] {
		background-position: 0 -1044px;
	}
	&.tkachiev48[data-achievement="28"] {
		background-position: 0 -1073px;
	}
	&.tkachiev48[data-achievement="29"] {
		background-position: 0 -1102px;
	}
	&.tkachiev48[data-achievement="3"] {
		background-position: 0 -1131px;
	}
	&.tkachiev48[data-achievement="30"] {
		background-position: 0 -1160px;
	}
	&.tkachiev48[data-achievement="31"] {
		background-position: 0 -1189px;
	}
	&.tkachiev48[data-achievement="32"] {
		background-position: 0 -1218px;
	}
	&.tkachiev48[data-achievement="65"] {
		background-position: 0 -1247px;
	}
	&.tkachiev48[data-achievement="34"] {
		background-position: 0 -1276px;
	}
	&.tkachiev48[data-achievement="35"] {
		background-position: 0 -1305px;
	}
	&.tkachiev48[data-achievement="64"] {
		background-position: 0 -1334px;
	}
	&.tkachiev48[data-achievement="36"] {
		background-position: 0 -1363px;
	}
	&.tkachiev48[data-achievement="37"] {
		background-position: 0 -1392px;
	}
	&.tkachiev48[data-achievement="38"] {
		background-position: 0 -1421px;
	}
	&.tkachiev48[data-achievement="39"] {
		background-position: 0 -1450px;
	}
	&.tkachiev48[data-achievement="4"] {
		background-position: 0 -1479px;
	}
	&.tkachiev48[data-achievement="40"] {
		background-position: 0 -1508px;
	}
	&.tkachiev48[data-achievement="41"] {
		background-position: 0 -1537px;
	}
	&.tkachiev48[data-achievement="42"] {
		background-position: 0 -1566px;
	}
	&.tkachiev48[data-achievement="43"] {
		background-position: 0 -1595px;
	}
	&.tkachiev48[data-achievement="44"] {
		background-position: 0 -1624px;
	}
	&.tkachiev48[data-achievement="45"] {
		background-position: 0 -1653px;
	}
	&.tkachiev48[data-achievement="46"] {
		background-position: 0 -1682px;
	}
	&.tkachiev48[data-achievement="47"] {
		background-position: 0 -1711px;
	}
	&.tkachiev48[data-achievement="48"] {
		background-position: 0 -1740px;
	}
	&.tkachiev48[data-achievement="49"] {
		background-position: 0 -1769px;
	}
	&.tkachiev48[data-achievement="5"] {
		background-position: 0 -1798px;
	}
	&.tkachiev48[data-achievement="50"] {
		background-position: 0 -1827px;
	}
	&.tkachiev48[data-achievement="51"] {
		background-position: 0 -1856px;
	}
	&.tkachiev48[data-achievement="52"] {
		background-position: 0 -1885px;
	}
	&.tkachiev48[data-achievement="53"] {
		background-position: 0 -1914px;
	}
	&.tkachiev48[data-achievement="54"] {
		background-position: 0 -1943px;
	}
	&.tkachiev48[data-achievement="55"] {
		background-position: 0 -1972px;
	}
	&.tkachiev48[data-achievement="56"] {
		background-position: 0 -2001px;
	}
	&.tkachiev48[data-achievement="57"] {
		background-position: 0 -2030px;
	}
	&.tkachiev48[data-achievement="58"] {
		background-position: 0 -2059px;
	}
	&.tkachiev48[data-achievement="59"] {
		background-position: 0 -2088px;
	}
	&.tkachiev48[data-achievement="6"] {
		background-position: 0 -2117px;
	}
	&.tkachiev48[data-achievement="60"] {
		background-position: 0 -2146px;
	}
	&.tkachiev48[data-achievement="61"] {
		background-position: 0 -2175px;
	}
	&.tkachiev48[data-achievement="62"] {
		background-position: 0 -2204px;
	}
	&.tkachiev48[data-achievement="63"] {
		background-position: 0 -2233px;
	}
	&.tkachiev48[data-achievement="70"] {
		background-position: 0 -2262px;
	}
	
	&.tkachiev80[data-achievement="18"] {
		background-position: 0 -2627px;
	}
	&.tkachiev80[data-achievement="107"] {
		background-position: 0 -2677px;
	}
	&.tkachiev80[data-achievement="37"] {
		background-position: 0 -2727px;
	}
	&.tkachiev80[data-achievement="19"] {
		background-position: 0 -2777px;
	}
	&.tkachiev80[data-achievement="38"] {
		background-position: 0 -2827px;
	}
	&.tkachiev80[data-achievement="10"] {
		background-position: 0 -2877px;
	}
	&.tkachiev80[data-achievement="39"] {
		background-position: 0 -2927px;
	}
	&.tkachiev80[data-achievement="2"] {
		background-position: 0 -2977px;
	}
	&.tkachiev80[data-achievement="4"] {
		background-position: 0 -3027px;
	}
	&.tkachiev80[data-achievement="108"] {
		background-position: 0 -3077px;
	}
	&.tkachiev80[data-achievement="40"] {
		background-position: 0 -3127px;
	}
	&.tkachiev80[data-achievement="20"] {
		background-position: 0 -3177px;
	}
	&.tkachiev80[data-achievement="41"] {
		background-position: 0 -3227px;
	}
	&.tkachiev80[data-achievement="9"] {
		background-position: 0 -3277px;
	}
	&.tkachiev80[data-achievement="42"] {
		background-position: 0 -3327px;
	}
	&.tkachiev80[data-achievement="21"] {
		background-position: 0 -3377px;
	}
	&.tkachiev80[data-achievement="43"] {
		background-position: 0 -3427px;
	}
	&.tkachiev80[data-achievement="109"] {
		background-position: 0 -3477px;
	}
	&.tkachiev80[data-achievement="44"] {
		background-position: 0 -3527px;
	}
	&.tkachiev80[data-achievement="22"] {
		background-position: 0 -3577px;
	}
	&.tkachiev80[data-achievement="45"] {
		background-position: 0 -3627px;
	}
	&.tkachiev80[data-achievement="103"] {
		background-position: 0 -3677px;
	}
	&.tkachiev80[data-achievement="46"] {
		background-position: 0 -3727px;
	}
	&.tkachiev80[data-achievement="23"] {
		background-position: 0 -3777px;
	}
	&.tkachiev80[data-achievement="47"] {
		background-position: 0 -3827px;
	}
	&.tkachiev80[data-achievement="11"] {
		background-position: 0 -3877px;
	}
	&.tkachiev80[data-achievement="48"] {
		background-position: 0 -3927px;
	}
	&.tkachiev80[data-achievement="24"] {
		background-position: 0 -3977px;
	}
	&.tkachiev80[data-achievement="49"] {
		background-position: 0 -4027px;
	}
	&.tkachiev80[data-achievement="36"] {
		background-position: 0 -4077px;
	}
	&.tkachiev80[data-achievement="5"] {
		background-position: 0 -4127px;
	}
	&.tkachiev80[data-achievement="25"] {
		background-position: 0 -4177px;
	}
	&.tkachiev80[data-achievement="50"] {
		background-position: 0 -4227px;
	}
	&.tkachiev80[data-achievement="12"] {
		background-position: 0 -4277px;
	}
	&.tkachiev80[data-achievement="51"] {
		background-position: 0 -4327px;
	}
	&.tkachiev80[data-achievement="26"] {
		background-position: 0 -4377px;
	}
	&.tkachiev80[data-achievement="52"] {
		background-position: 0 -4427px;
	}
	&.tkachiev80[data-achievement="104"] {
		background-position: 0 -4477px;
	}
	&.tkachiev80[data-achievement="53"] {
		background-position: 0 -4527px;
	}
	&.tkachiev80[data-achievement="27"] {
		background-position: 0 -4577px;
	}
	&.tkachiev80[data-achievement="54"] {
		background-position: 0 -4627px;
	}
	&.tkachiev80[data-achievement="13"] {
		background-position: 0 -4677px;
	}
	&.tkachiev80[data-achievement="55"] {
		background-position: 0 -4727px;
	}
	&.tkachiev80[data-achievement="28"] {
		background-position: 0 -4777px;
	}
	&.tkachiev80[data-achievement="56"] {
		background-position: 0 -4827px;
	}
	&.tkachiev80[data-achievement="8"] {
		background-position: 0 -4877px;
	}
	&.tkachiev80[data-achievement="57"] {
		background-position: 0 -4927px;
	}
	&.tkachiev80[data-achievement="29"] {
		background-position: 0 -4977px;
	}
	&.tkachiev80[data-achievement="58"] {
		background-position: 0 -5027px;
	}
	&.tkachiev80[data-achievement="14"] {
		background-position: 0 -5077px;
	}
	&.tkachiev80[data-achievement="59"] {
		background-position: 0 -5127px;
	}
	&.tkachiev80[data-achievement="3"] {
		background-position: 0 -5177px;
	}
	&.tkachiev80[data-achievement="6"] {
		background-position: 0 -5227px;
	}
	&.tkachiev80[data-achievement="105"] {
		background-position: 0 -5277px;
	}
	&.tkachiev80[data-achievement="60"] {
		background-position: 0 -5327px;
	}
	&.tkachiev80[data-achievement="30"] {
		background-position: 0 -5377px;
	}
	&.tkachiev80[data-achievement="61"] {
		background-position: 0 -5427px;
	}
	&.tkachiev80[data-achievement="15"] {
		background-position: 0 -5477px;
	}
	&.tkachiev80[data-achievement="62"] {
		background-position: 0 -5527px;
	}
	&.tkachiev80[data-achievement="31"] {
		background-position: 0 -5577px;
	}
	&.tkachiev80[data-achievement="63"] {
		background-position: 0 -5627px;
	}
	&.tkachiev80[data-achievement="1"] {
		background-position: 0 -5677px;
	}
	&.tkachiev80[data-achievement="64"] {
		background-position: 0 -5727px;
	}
	&.tkachiev80[data-achievement="32"] {
		background-position: 0 -5777px;
	}
	&.tkachiev80[data-achievement="65"] {
		background-position: 0 -5827px;
	}
	&.tkachiev80[data-achievement="16"] {
		background-position: 0 -5877px;
	}
	&.tkachiev80[data-achievement="66"] {
		background-position: 0 -5927px;
	}
	&.tkachiev80[data-achievement="33"] {
		background-position: 0 -5977px;
	}
	&.tkachiev80[data-achievement="67"] {
		background-position: 0 -6027px;
	}
	&.tkachiev80[data-achievement="106"] {
		background-position: 0 -6077px;
	}
	&.tkachiev80[data-achievement="68"] {
		background-position: 0 -6127px;
	}
	&.tkachiev80[data-achievement="34"] {
		background-position: 0 -6177px;
	}
	&.tkachiev80[data-achievement="69"] {
		background-position: 0 -6227px;
	}
	&.tkachiev80[data-achievement="17"] {
		background-position: 0 -6277px;
	}
	&.tkachiev80[data-achievement="7"] {
		background-position: 0 -6327px;
	}
	&.tkachiev80[data-achievement="35"] {
		background-position: 0 -6377px;
	}
	&.tkachiev80[data-achievement="70"] {
		background-position: 0 -6427px;
	}
	&.tkachiev80[data-achievement="72"] {
		background-position: 0 -6477px;
	}
	&.tkachiev80[data-achievement="71"] {
		background-position: 0 -6527px;
	}
}
