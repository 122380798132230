.datatable {
	& td[data-path="root/body/row/cell"] > input.datatable-input {
		width: 100%;
	}
	
	& [data-path="root/body/row/cell"] > input.datatable-input {
		width: inherit;
		max-width: inherit;
	}
	
	input.datatable-input {
		height: .87rem;
		font-size: .75rem;
	}
	
	& [data-path="root/body/row/cell"] > input.datatable-error {
		background-color: $error-background-color;
		color: $error-background-fontcolor;
	}
	
	& [data-path="root/head/row/cell"] {
		text-align: left;
	}
	
	& [data-path="root/head/row/cell"][data-sortable="1"] {
		cursor: pointer;
	}
	
	& .datatable-forcedwidth {
		overflow-x: hidden;
	}
}
