@import '../shared';

.dropdown {
  &.full-width {
    width: 100%;
    button {
      width: 100%;
      text-align: left; }
    div:has(> i.icon) {
      float: right; } }

  .menu {
    .menu-item {
      a {
        cursor: pointer; } } } }
