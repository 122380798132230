.legacyTravianUnitImage {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    &.size-16 {
        width: 16px;
        height: 16px;
        background-position: 0 0; }
    &.size-26 {
        width: 26px;
        height: 26px; }
    &.filter-grayscale {
        filter: grayscale(100%); }

    @for $tribeId from 1 through 9 {
        @for $unitId from 1 through 10 {
            &.tribe-#{$tribeId}.troopId-#{$unitId} {
                &.size-16 {
                    background-image: url('../img/travian/Unit/vid#{$tribeId}/t#{$unitId}_small.png'); }
                &.size-26 {
                    background-image: url('../img/travian/Unit/vid#{$tribeId}/t#{$unitId}_medium.png'); } } } } }
