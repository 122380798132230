@import '../shared';

.bt-with-tooltip {
  display: inline-block; }

.bt-tooltip {
  display: inline-block;
  transition: opacity 300ms;
  background-color: $bg-color-dark;
  position: fixed;
  box-shadow: 0 0 7px 1px $shadow-color;
  padding: .4rem;
  pointer-events: none;
  z-index: 1000000; }
