.cover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    .bgimg {
        position: relative;
        min-height: 400px;
        overflow: hidden;

        &:before {
            content : "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -3;

            opacity: 0.6;
            background-color: #5FB919;
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            will-change: transform;
        }

        &.bgimg-fill {
            min-height: 100%;
        }

        &.bgimg-tk:before {
            background-image: url('../img/bg/bg_tk.jpg');
        }

        &.bgimg-forum:before {
            background-image: url('../img/bg/forum.jpg');
        }

        &.bgimg-village:before {
            background-image: url('../img/bg/village.jpg');
        }
    }

    .caption {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        margin-top: -45px;

        text-align: center;
        text-shadow: 0 0 5px #AAA;
        color: rgba(0, 0, 0, 0.65);
        font-family: 'Raleway', sans-serif; font-weight: 200;

        .border {
            display: inline-block;
            padding: 15px 25px;
            font-size: 42px;

            &.border-light {
                color: #f7f7f7;
                background-color: transparent;
            }

            &.border-dark {
                color: #fff;
                background-color: rgba(17, 17, 17, 0.65);
            }
        }

        .anchor {
            color: $primary-color;
            display: inline;
            visibility: hidden;
            margin: 0 4px 0 -35px;
            padding: 0 4px;
            
            &:focus, &:hover {
                text-decoration: none;
            }
        }
    }
        
    *:hover .caption .anchor {
        visibility: visible;
    }

    .paragraph {
        padding: 50px 80px;
        text-align: center, justify;

        &.paragraph-light {
            color: #777;
            background-color:white;
        }

        &.paragraph-dark {
            color: #ddd;
            background-color: #282E34;
        }

        h3 {
            color: inherit;
            text-align: center;
        }

        p, .p {
            max-width: 960px;
            margin: 0 auto;
            overflow: auto;
        }

        footer, .footer {
            max-width: 960px;
            margin: 30px auto 0 auto;
            text-align: center;
            overflow: auto;

            .btn.btn-xl {
                height: auto;
                padding: 15px 25px;
                font-size: 120%;
                overflow: auto;
            }
        }

        ul {
            li {
                font-size: 110%;
            }
        }
    }
    
    h3 {
        letter-spacing: 5px;
        text-transform: uppercase;
        font: 20px "Lato", sans-serif;
        color: rgba(17, 17, 17, 0.65);
    }

    .cover-nav {
        height: 3.8rem;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;
        
        .btn {
            position: absolute;
            right: 1.5rem;
            top: 1rem;
        }
    }

    .cover-footer {
        font-size: 90%;
        color: inherit;
        
        a {
            color: #667189;
            margin: 0 5px;
        }
    }
    
    // /* Turn off parallax scrolling for tablets and phones */
    // @media only screen and (max-device-width: 1024px) {
    //     .bgimg-1, .bgimg-2, .bgimg-3 {
    //         background-attachment: scroll;
    //     }
    // }

    @media (max-device-width: 600px) {
        .paragraph {
            padding: 30px 25px;
        }
        
        .bgimg {
            min-height: 200px;

            &:not(.bgimg-forum):before {
                background-image: none !important;
            }
        }

        .caption {
            .anchor {
                visibility: visible;
            }
        }
    }
}


@-moz-document url-prefix() {
    .cover .bgimg:before {
        will-change: auto !important;
    }
}
