.showif {
	display: none;
}

body.showif-set-login .showif-login,
body.showif-set-logout .showif-logout,
body.showif-set-tv-kingdoms .showif-tk,
body.showif-set-tv-legends .showif-tl
{
	display: initial !important;
}

body.showif-set-login tr.showif-login,
body.showif-set-logout tr.showif-logout,
body.showif-set-tv-kingdoms tr.showif-tk,
body.showif-set-tv-legends tr.showif-tl
{
	display: table-row !important;
}

body.showif-set-login .disabledif-login,
body.showif-set-logout .disabledif-logout
{
	filter: grayscale(60%);
	cursor: not-allowed;
}

.showif-help-enabled-parser,
.showif-help-enabled-tklogin {
	display: block !important;
}
