.main-footer {
    margin-top: 20px;
    font-size: 90%;
    color: #acb3c2;
    padding: .5rem .5rem 1.5rem .5rem;

    a {
        color: #667189;
    }
}

@media (max-width: 1280px) {
    .main-footer {
        display: none;
    }
}