.travianServerList {
  width: 100%;
  min-width: 1024px;
  max-width: 1440px;

  thead > tr > td {
    font-weight: bold; }

  td.future-start {
    color: #C15232;
    font-weight: bold; }

  td.speed-2 {
    color: #0000FF; }

  td.speed-3 {
    color: #00A000; }

  td.speed-5 {
    color: #FF8C00; }

  td.speed-10 {
    color: #FF0000;
    font-weight: bold; } }

.updaterInfo {
  font-size: small; }
