.btn {
    &.btn-round {
        border-radius: 50%;

        &.btn-link {
            border: $border-width solid $link-color;

            &:focus,
            &:hover,
            &:active,
            &.active {
                color: $link-color-dark;
            }
        }
    }

    &.btn-xs {
        font-size: .5rem;
        line-height: .75rem;
        padding: 0 .1rem;
        height: 1rem;
    }
}

.buttonlike { /* 32x32 button for unit or other fitting icons */
    border: 1px solid #808080;
    box-shadow: 1px 1px 1px 1px #888888;
    cursor: pointer;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    
    & > * {
        vertical-align: middle;
    }
    
    &.selected {
        border: 1px solid #FF0000;
        box-shadow: 1px 1px 1px 1px rgba(255, 0, 0, 0.5);
    }
}

.btn-with-gap + .btn-with-gap {
    margin-left: .2rem;
}