.vspace-between-1 + .vspace-between-1 {
  margin-top: .2rem; }

.vspace-between-2 + .vspace-between-2 {
  margin-top: .4rem; }

.hspace-between-1 + .hspace-between-1 {
  margin-top: .2rem; }

.hspace-between-2 + .hspace-between-2 {
  margin-top: .4rem; }
