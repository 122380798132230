/**
 *  Shared Sass File
 *  ----------------
 *
 *  Should contain: mixins, variables and other stuff that will not be exported to css
 *  Should NOT contain: style rules, because these would be exported multiple times to the main.css and the components
 *
 */

// Variables and mixins
$primary-color: #C15232;
$error-background-color: #FF0000;
$error-background-fontcolor: white;
$zero-value-color: #D8D8D8;
$primary-background-color: white;
$shadow-color: #888;

@import "node_modules/spectre.css/src/_variables";
@import "node_modules/spectre.css/src/_mixins";

@mixin large-text {
    font: {
        family: Arial;
        size: 20px;
        weight: bold;
    }
    
    color: #ff0000;
}
